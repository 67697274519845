export const products = [
  {
    id: 1,
    img: "img/coder2.jpg",
    link: "https://github.com/basantapoudel123",
  },
  {
    id: 2,
    img: "img/coder2.jpg",
    link: "https://github.com/basantapoudel123",
  },
  {
    id: 3,
    img: "img/coder3.jpg",
    link: "https://github.com/basantapoudel123",
  },
  {
    id: 4,
    img: "img/coder4.jpg",
    link: "https://github.com/basantapoudel123",
  },
  {
    id: 5,
    img: "img/coder5.jpg",
    link: "https://github.com/basantapoudel123",
  },
  {
    id: 6,
    img: "img/coder1.jpg",
    link: "https://github.com/basantapoudel123",
  },
];
